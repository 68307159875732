<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>HP-TECHNIK</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          Series PON PUMP
        </div>
      </div>
    </div>
    <div class="contents">
      <div class="box1">
        <img src="@/img/상세페이지/HP/목록/PONpump.jpg" alt="" />
        <div>
          <h2>시리즈 PON, 통합 오버플로 밸브, 바이패스 및 통합 필터 포함</h2>
          <p>
            PON 산업용 펌프 프로그램은
            <strong style="font-weight: 600"
              >견고하고 내구성이 뛰어난 구조를</strong
            >
            특징으로 하며 다양한 응용 분야에 사용할 수 있습니다. 펌프는 역청,
            첨가제, 연료, 중유뿐만 아니라 식물성 기름과 같이 깨끗하거나 약간
            오염된 매체를 펌핑하는 데 이상적입니다. 따라서 이 시리즈는 투여
            시스템, 공작 기계, 터빈 또는 건조 시스템에 적합하며 공작 기계, 터빈
            또는 건조 시스템에 사용되는 훨씬 더 많은 용도에 적합합니다. 펌프는
            바람직하게는 버너에 직접 설치되도록 설계되고 펌프 하우징 에 필터
            요소가 통합되어 있습니다 . 이는 작업자가 흡입 라인에 필터를 설치할
            필요가 없음을 의미합니다.
          </p>
        </div>
      </div>
      <div class="box2">
        <div>
          <h2>세부</h2>
          <li>자체 프라이밍</li>
          <li>자기 윤활</li>
          <li>맥동이 거의 없고 조용하게 작동하며,</li>
          <li>실질적으로 유지보수가 필요 없으며 내압 및 진공 밀봉 샤프트 씰</li>
          <li>배출 범위: 45 ~ 1050l/h</li>
          <li>작동 압력: 0 – 40bar</li>
          <li>온도: 최대 150°C(요청 시 더 높은 온도)</li>
          <li>
            회전의 방향

            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I
            = 간접 – 시계 반대 방향<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;D
            = 정방향 - 시계 방향
          </li>
          <li>
            노즐 포트<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R
            = 오른쪽<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L
            = 왼쪽
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;회전방향은
            공장에서만 변경할 수 있습니다. 따라서 주문시 반드시<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            치수표에 따라 원하는 회전 방향과 노즐 포트 방향을 기재하시기
            바랍니다.
          </li>
          <li>
            2파이프 설치용 펌프의 표준 설계, 1파이프 작동으로 직접 전환 가능(
            작동 및 유지보수 설명서 참조 )
          </li>
          <li>
            모든 hp-Industrial PON 모델 펌프에는 가열 H3, 전기 대기 및 온도 조절
            장치 없이 보조 가열 장치가 장착될 수 있습니다.
          </li>
        </div>
        <div>
          <h2>적용 분야</h2>
          <li>건설</li>
          <li>농업</li>
          <li>해양/해상</li>
        </div>
      </div>
      <div class="box3">
        <div class="box3_title">
          <h2>기술 선택 차트</h2>
          <div>HP-최대 40 BAR의 PON 시리즈 내부 기어 펌프</div>
          <img
            src="@/img/상세페이지/HP/표1.png"
            alt=""
            style="width: 840px; margin-top: 20px"
          />
        </div>
      </div>
      <div class="box4">
        <h2>시리즈 PON의 곡선</h2>
        <img
          src="@/img/상세페이지/HP/표2.png"
          alt=""
          style="width: 840px; margin-top: 20px"
        />
      </div>
      <div class="box4">
        <h2>시리즈 PON의 축척 도면</h2>
        <img
          src="@/img/상세페이지/HP/표3.gif"
          alt=""
          style="width: 840px; margin-top: 20px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box1 img {
  width: 300px;
  border-radius: 4px;
  margin-right: 30px;
}
.box1 > div > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
}
.box1 > div > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box2 {
  width: 840px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box2 > div > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
  margin-top: 40px;
}
.box2 > div > li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box3,
.box4 {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box3_title h2,
.box4 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-top: 40px;
}
.box3_title div {
  font-size: 20px;
  color: #686868;
  margin-top: 20px;
}
</style>
