<template>
  <div class="danfoss">
    <bfp_popup v-if="bfp_pop == true"></bfp_popup>
    <div class="title">
      <div>
        <h2>DANFOSS</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          MBS3000 and MBS 3050
        </div>
      </div>
      <a href="/file/danfoss_MBS.pdf" target="_blank">
        <span style="font-size: 15px">danfoss_MBS.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="imgbox">
        <img src="@/img/상세페이지/댄포스/목록/danfoss_mbs.jpg" alt="" />
        <div>
          <h3
            style="
              color: black;
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 20px;
              line-height: 1.6;
            "
          >
            가혹한 환경에서도 신뢰성있는 압력 측정
          </h3>
          소형 압력 트랜스미터 MBS 3000은 거의 모든 산업 어플리케이션에서 사용할
          수 있도록 설계되어 있으며 가장 가혹한 환경에서조차 안정적인 압력
          측정을 제공합니다.
        </div>
      </div>
      <div class="mentbox">
        <p>
          진동 제어장치가 통합된 소형 헤비듀티 압력 트랜스미터 MBS 3050은
          공동현상, 액해머, 압력 피크와 같이 심각한 매질의 영향을 받는 유압
          애플리케이션에서 사용할 수 있도록 설계되었으며 가혹한 환경에서도
          신뢰성있는 압력 측정치를 제공합니다.<br /><br />
          유연한 압력 트랜스미터 프로그램은 다양한 출력 신호를 다루고 절대 및
          게이지(상대값) 버전이 있으며 0~1bar에서 0~600bar까지 측정하고 광범위한
          압력 및 전기적 연결에 적용 가능합니다.<br /><br />
          압력 트랜스미터에는 우수한 진동 안정성, 견고한 구조 및 높은 수준의
          EMC/EMI 보호 기능이 내장되어 있어 엄격한 산업 요구사항을 대부분
          충족시킵니다.
        </p>
      </div>
      <div class="goodpoint">
        <h2>기능 및 장점</h2>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>극한 산업 환경 및 유압 환경에서 사용할 수 있도록 설계</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>공동현상, 액해머, 압력 피크에 저항 (MBS 3050)</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>내산성 인클로저 및 습식 부품</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>스테인리스강 (AISI 316L)</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>
            0bar부터 600bar까지 상대값(게이지) 또는 절대값으로 표시되는 압력
            범위
          </div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>모든 표준 출력 신호: 4~20mA, 0~5V, 1~5V, 1~6V, 0~10V, 1~10V</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>광범위한 압력 및 전기 연결</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>온도 보상 및 레이저 보정</div>
        </div>
      </div>
    </div>
    <div class="data_sheet">
      <a href="/file/danfoss_MBS.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
import bfp_popup from "./danfoss_bfp_document.vue";
export default {
  components: {
    bfp_popup,
  },
  computed: {
    bfp_pop() {
      return this.$store.state.danfoss_bfp_popup;
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    documentBtn() {
      this.$store.commit("danfoss_bfp_popup");
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.contents {
  width: 840px;

  margin-top: 50px;
}
.imgbox {
  display: flex;
  align-items: flex-start;
}
.imgbox img {
  width: 400px;
  margin-right: 25px;
  border-radius: 4px;
}
.imgbox div {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.mentbox {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
  margin-top: 40px;
}
.goodpoint {
  margin-top: 100px;
}
.goodpoint h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 40px;
}
.goodpoint > div {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.goodpoint > div > img {
  width: 20px;
  margin-right: 10px;
}
.goodpoint > div > div {
  color: #191919;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 20px;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
