<template>
  <div class="foo">
    <img src="@/img/home/logo.jpg" alt="" />
    <div class="info">
      <div>
        <div style="margin-bottom: 8px">대표명 : 김정호</div>
        <div>
          사업장 소재지 : 서울특별시 구로구 구로동 212-30 에이스트윈타워2차
          609호
        </div>
      </div>
      <div>
        <div style="margin-bottom: 8px">
          대표 전화번호 : 02-2277-4996 / 02-2109-5506~7
        </div>
        <div>팩스 : 02-2277-7735</div>
      </div>
      <div style="display: flex; align-items: center">
        <div style="margin-right: 10px">이메일 : hago@dongjootnc.co.kr</div>
        <img
          src="@/img/copyIcon.png"
          alt=""
          style="width: 18px; margin: 0; cursor: pointer"
          @click="copyBtn"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    copyBtn() {
      window.navigator.clipboard.writeText("hago@dongjootnc.co.kr");
    },
  },
};
</script>

<style scoped>
.foo {
  border-top: 1px solid #dbdbdb;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 2;
}
.foo img {
  width: 150px;
  margin-right: 30px;
  margin-left: 30px;
}
.info {
  display: flex;
  align-items: center;
}
.info div {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 30px;
}
</style>
