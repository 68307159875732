<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>HP-TECHNIK</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          SERIES B-PRO VALVE
        </div>
      </div>
    </div>
    <div class="contents">
      <div class="box1">
        <img src="@/img/상세페이지/HP/목록/B-PRO.jpg" alt="" />
        <div>
          <h2>시리즈 B-PRO의 압력 조절 밸브</h2>
          <p>
            B-Pro 밸브는 시스템 압력과 유량을 제어하는 ​​데 사용됩니다. 최대
            용량이 2000l/h인 4가지 크기로 제공 됩니다 . 밸브는 스필백 노즐과
            관련하여 사용되며 스필백의 압력과 흐름이 노즐 용량을 결정합니다.
            밸브는 역류 노즐이 있는 오일 버너의 경유 및 중유에 사용하기 에
            적합합니다 .
          </p>
        </div>
      </div>
      <div class="box2">
        <div>
          <h2>재료</h2>
          <li>
            유압 주철 케이싱(GGG 40); 요청 시 인증서; 피스톤, 밸브 팁, 경화강
            스프링
          </li>
        </div>
        <div>
          <h2>세부</h2>
          <li>유체 매체의 최대 온도: 150 °C</li>
        </div>
        <img
          src="@/img/상세페이지/HP/bpro1.png"
          alt=""
          style="width: 840px; margin-top: 20px"
        />
      </div>
      <div class="box3">
        <div class="box3_title">
          <h2>유량</h2>
          <img
            src="@/img/상세페이지/HP/bpro2.png"
            alt=""
            style="width: 840px; margin-top: 20px"
          />
        </div>
      </div>
      <img
        src="@/img/상세페이지/HP/bpro3.png"
        alt=""
        style="width: 840px; margin-top: 20px"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box1 img {
  width: 300px;
  border-radius: 4px;
  margin-right: 30px;
}
.box1 > div > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
}
.box1 > div > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box2 {
  width: 840px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box2 > div > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
  margin-top: 40px;
}
.box2 > div > li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box3,
.box4 {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box3_title h2,
.box4 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-top: 40px;
}
.box3_title div {
  font-size: 20px;
  color: #686868;
  margin-top: 20px;
}
</style>
