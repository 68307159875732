<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>SUNTEC</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          PUMP E
        </div>
      </div>
      <a href="/file/suntec/PUMP_E.pdf" target="_blank">
        <span style="font-size: 15px">PUMP_E.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="box1">
        <img
          src="@/img/상세페이지/suntec/목록/suntec_e.png"
          alt=""
          style="width: 250px"
        />
        <div>
          <p>
            SUNTEC E 오일 펌프는 중유 용도로 특별히 설계되었습니다. 고온에
            견디는 특수 샤프트 씰 유형과 냉간 시동을 용이하게 하는 예열기 위치가
            장착되어 있습니다.
          </p>
          <img
            src="@/img/상세페이지/suntec/suntec_e1.png"
            alt=""
            style="width: 300px; margin-top: 15px"
          />
        </div>
      </div>
      <div class="box2">
        <div>
          <p>
            기어 세트는 내장된 필터를 통해 탱크에서 오일을 끌어와 노즐 라인의
            오일 압력을 조절하는 밸브로 전달합니다. <br />노즐 라인을 통과하지
            않는 모든 오일은 밸브를 통해 2개 파이프 설치의 경우 리턴 라인으로
            다시 우회되거나, 1개 파이프 설치의 경우 기어 세트의 흡입 포트로 다시
            돌아갑니다. <br />이 경우 바이패스 플러그를 리턴 포트에서 제거하고
            리턴 포트를 강철 플러그와 와셔로 밀봉해야 합니다.<br /><br />
            <strong style="font-weight: 600; color: black">블리드</strong><br />
            2배관 작동시 블리드가 자동으로 이루어지나, 압력계 포트의 플러그를
            느슨하게 하면 가속될 수 있습니다. 한 번의 파이프 작업에서는
            시스템에서 공기를 빼내기 위해 압력 포트를 열어야 합니다.
            <br /><br />
            <strong style="font-weight: 600; color: black">참고</strong><br />
            모델 1069에는 컷오프 기능이 없습니다. 차단은 외부 솔레노이드 밸브에
            의해 제공되어야 합니다.
          </p>
        </div>
        <div>
          <img
            src="@/img/상세페이지/suntec/suntec_e2.png"
            style="width: 250px; margin-left: 15px"
          />
        </div>
      </div>
      <img
        src="@/img/상세페이지/suntec/suntec_e3.png"
        style="width: 840px; margin-top: 60px"
      />
    </div>
    <div class="data_sheet">
      <a href="/file/suntec/PUMP_E.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}

.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box1 > div > p,
.box2 > div > p {
  color: #686868;
  line-height: 1.7;
}
.box2 {
  margin-top: 55px;
  display: flex;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
