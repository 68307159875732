<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>HP-TECHNIK</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          OVERFLOW VALVES WITH THREAD CONNECTION
        </div>
      </div>
    </div>
    <div class="contents">
      <div class="box1">
        <img src="@/img/상세페이지/HP/목록/overflow.jpg" alt="" />
        <div>
          <h2>스레드 연결 방식의 오버플로 밸브</h2>
          <p>
            나사 연결부가 있는 오버플로우 밸브는 작동 유형 E: 조정 나사를
            사용하여 사용됩니다.<br /><br />
            이 밸브는 윤활유, 유압유 및 기타 자가 윤활 비부식성 유체에
            사용하기에 적합합니다. 그들은 우수한 난방유 EL, L, M, S 및 ES,
            콜타르 오일 및 등유를 제공합니다.
          </p>
          <div>
            <h2>세부</h2>
            <li>유체 매체의 최대 온도: 150 °C</li>
            <li>
              기능: 설정된 작동 압력 또는 압력 파이프의 설정된 최대 압력을
              유지하기 위한 직접 제어식 스프링 장착 오버플로 밸브
            </li>
          </div>
        </div>
      </div>
      <img
        src="@/img/상세페이지/HP/valve3_1.png"
        alt=""
        style="
          width: 840px;
          margin-top: 20px;
          border-bottom: 1px solid #dbdbdb;
          padding-bottom: 40px;
        "
      />
      <div class="box3">
        <div class="box3_title">
          <img
            src="@/img/상세페이지/HP/valve3_2.png"
            alt=""
            style="width: 840px; margin-top: 40px"
          />
        </div>
      </div>
      <div class="box3">
        <div class="box3_title">
          <img
            src="@/img/상세페이지/HP/valve3_3.png"
            alt=""
            style="width: 840px; margin-top: 40px"
          />
        </div>
      </div>
      <div class="box3">
        <div class="box3_title">
          <img
            src="@/img/상세페이지/HP/valve3_4.png"
            alt=""
            style="width: 840px; margin-top: 40px"
          />
        </div>
      </div>
      <div class="box3">
        <div class="box3_title">
          <h2>사이즈표</h2>

          <img
            src="@/img/상세페이지/HP/valve3_5.png"
            alt=""
            style="width: 840px; margin-top: 40px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}
.box1 img {
  width: 300px;
  border-radius: 4px;
  margin-right: 30px;
}
.box1 > div > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
}
.box1 > div > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box2 {
  width: 840px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box1 > div > div > h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
  margin-top: 10px;
}
.box1 > div > div > li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box3,
.box4 {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box3_title h2,
.box4 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-top: 40px;
}
.box3_title p {
  font-size: 16px;
  color: #686868;
  margin-top: 20px;
}
</style>
