import { render, staticRenderFns } from "./nippon.vue?vue&type=template&id=095f58dc&scoped=true&"
import script from "./nippon.vue?vue&type=script&lang=js&"
export * from "./nippon.vue?vue&type=script&lang=js&"
import style0 from "./nippon.vue?vue&type=style&index=0&id=095f58dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095f58dc",
  null
  
)

export default component.exports