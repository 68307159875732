const notice = {
  state() {
    return {
      notice: [
        {
          title: "제목입니다.",
          detail: "내용입니다.",
        },
        {
          title: "2제목입니다.",
          detail: "2내용입니다.",
        },
      ],
    };
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default notice;
