<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>HAGO</h2>
      </div>
    </div>
    <div class="contents">
      <div @click="goHogo_nozzle">
        <img src="@/img/상세페이지/하고/하고노즐.jpg" alt="" />
        <div>가습노즐</div>
        <div>(HUMIDIFYING NOZZLES)</div>
      </div>
      <!-- <div @click="goDanfoss_nozzle">
        <img src="@/img/상세페이지/댄포스/목록/댄포스노즐.jpg" alt="" />
        <div>OIL NOZZLE</div>
      </div>
      <div style="margin-right: 0" @click="goDanfoss_bfp">
        <img src="@/img/상세페이지/댄포스/목록/댄포스펌프.jpg" alt="" />
        <div>GEAR PUMP - BFP, RSA, RSH</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goHogo_nozzle() {
      this.$router.push({ path: "/items/hago_nozzle" }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contents {
  width: 840px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-top: 50px;
}
.contents > div {
  width: 250px;
  height: 250px;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: border 0.2s, color 0.2s;
}
.contents > div:hover {
  border: 1px solid red;
}
.contents > div > div {
  color: #686868;
}
.contents div img {
  width: 200px;
  margin-bottom: 30px;
  padding: 15px;
}
</style>
