<template>
  <div id="main">
    <div class="banner"><img src="@/img/home/s_banner.jpg" alt="" /></div>
    <div class="wrap">
      <div class="nav">
        <div class="nav_title">
          <h2>회사소개</h2>
        </div>
        <div class="item_listbox">
          <div @click="goCEOment">CEO 인사말</div>
          <div @click="goFindLoad">오시는길</div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.paramsID(this.$route.name);
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    paramsID(name) {},
    goCEOment() {
      this.$router.push({ name: "ceoment" }).catch(() => {});
    },
    goFindLoad() {
      this.$router.push({ name: "findload" }).catch(() => {});
    },
  },
  watch: {
    $route(to, form) {
      if (to.path !== form.path) this.paramsID(this.$route.name);
    },
  },
};
</script>

<style scoped>
#main {
  padding-top: 65px;
  width: 1080px;
  margin: 0 auto;
  margin-bottom: 150px;
}
.banner {
  width: 1080px;
  height: 300px;
  margin-bottom: 40px;
}
.banner img {
  width: 1080px;
  height: 300px;
  border-radius: 12px;
}
.wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.nav {
  width: 200px;
  border: 1px solid #dbdbdb;
  margin-right: 70px;
}
.nav_title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  border-bottom: 1px solid #dbdbdb;
}
.item_listbox {
  padding: 0 20px;
  margin-top: 20px;
}
.item_listbox h3 {
  padding: 8px 5px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}
.item_listbox h3:hover {
  background: rgba(255, 0, 0, 0.8);
  color: #fff;
}
.item_listbox div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding: 8px 10px;
  background: rgba(255, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}
.item_listbox div:hover {
  background-color: #fff;
  color: red;
}
</style>
