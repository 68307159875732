<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>HP-TECHNIK</h2>
      </div>
    </div>
    <div class="contents">
      <div @click="goHp_ponpump">
        <img
          src="@/img/상세페이지/HP/목록/PONpump.jpg"
          alt=""
          style="margin-bottom: -5px"
        />
        <div>Series PON PUMP</div>
      </div>
      <div @click="goHp_uhepump">
        <img
          src="@/img/상세페이지/HP/목록/UHEpump.jpg"
          alt=""
          style="margin-bottom: -5px"
        />
        <div>Series UHE PUMP</div>
      </div>
      <div @click="goHp_valve1">
        <img
          src="@/img/상세페이지/HP/목록/B-PRO.jpg"
          alt=""
          style="margin-bottom: -5px"
        />
        <div>SERIES B-PRO VALVE</div>
      </div>
      <div @click="goHp_valve2">
        <img
          src="@/img/상세페이지/HP/목록/MODUL.jpg"
          alt=""
          style="margin-bottom: -5px; width: 180px"
        />
        <div style="text-align: center">
          PRESSURE-REGULATING VALVE WITH MODULATING KIT
        </div>
      </div>
      <div @click="goHp_valve3">
        <img
          src="@/img/상세페이지/HP/목록/overflow.jpg"
          alt=""
          style="margin-bottom: -5px; width: 180px"
        />
        <div style="text-align: center">
          OVERFLOW VALVES WITH THREAD CONNECTION
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goDanfoss_nozzle() {
      this.$router.push({ path: "/items/danfoss_nozzle" });
    },
    goDanfoss_dpf() {
      this.$router.push({ path: "/items/danfoss_dpf" }).catch(() => {});
    },
    goDanfoss_bfp() {
      this.$router.push({ path: "/items/danfoss_bfp" }).catch(() => {});
    },
    goHp_ponpump() {
      this.$router.push({ path: "/items/hp_ponpump" }).catch(() => {});
    },
    goHp_uhepump() {
      this.$router.push({ path: "/items/hp_uhepump" }).catch(() => {});
    },
    goHp_valve1() {
      this.$router.push({ path: "/items/hp_valve1" }).catch(() => {});
    },
    goHp_valve2() {
      this.$router.push({ path: "/items/hp_valve2" }).catch(() => {});
    },
    goHp_valve3() {
      this.$router.push({ path: "/items/hp_valve3" }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contents {
  width: 840px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-top: 50px;
}
.contents > div {
  width: 250px;
  height: 250px;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 25px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: border 0.2s, color 0.2s;
}
.contents > div:hover {
  border: 1px solid red;
}
.contents > div > div {
  color: #686868;
}
.contents div img {
  width: 200px;
  margin-bottom: 30px;
  padding: 15px;
}
</style>
