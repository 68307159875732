import Vue from "vue";
import Vuex from "vuex";

//모듈
import notice from "./module/notice";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { notice },
  plugins: [],
  state() {
    return {
      danfoss_bfp_popup: false,
    };
  },
  getters: {},
  mutations: {
    danfoss_bfp_popup(state) {
      state.danfoss_bfp_popup = !state.danfoss_bfp_popup;
    },
  },
  actions: {},
});

export default store;
