<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>ANNAKA</h2>
      </div>
    </div>
    <div class="contents">
      <div @click="goAnnaka_glass">
        <img src="@/img/상세페이지/아나카/수면계유리1.png" alt="" />
        <div>GAUGE GLASS(수면계유리)</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goDanfoss_nozzle() {
      this.$router.push({ path: "/items/danfoss_nozzle" });
    },
    goDanfoss_dpf() {
      this.$router.push({ path: "/items/danfoss_dpf" }).catch(() => {});
    },
    goDanfoss_bfp() {
      this.$router.push({ path: "/items/danfoss_bfp" }).catch(() => {});
    },
    goAnnaka_glass() {
      this.$router.push({ path: "/items/annaka_glass" }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contents {
  width: 840px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-top: 50px;
}
.contents > div {
  width: 250px;
  height: 250px;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: border 0.2s, color 0.2s;
}
.contents > div:hover {
  border: 1px solid red;
}
.contents > div > div {
  color: #686868;
}
.contents div img {
  width: 200px;
  margin-bottom: 30px;
  padding: 15px;
}
</style>
