<template>
  <div>
    <div class="header">
      <img src="@/img/home/logo.jpg" alt="" @click="goHome" />
      <div @click="info_btn">회사소개</div>
      <div @click="items_btn">제품소개</div>
    </div>
    <transition name="fade" mode="out-in">
      <div class="side-bar" v-if="info == true">
        <div @click="goCEOment">CEO 인사말</div>
        <div @click="goFindLoad">오시는 길</div>
      </div>
      <div class="side-bar" v-if="items == true">
        <div @click="goDanfoss">DANFOSS</div>
        <div @click="goSuntec">SUNTEC</div>
        <div @click="goHago">HAGO</div>
        <div @click="goHp">HP-TECHNIK</div>
        <div @click="goAnnaka">ANNAKA</div>
        <div @click="goCaleffi">CALEFFI</div>
        <div @click="goNippon">NIPPON</div>
        <div @click="goDanfoss_nozzle">NOZZLE</div>
        <div @click="goDanfoss_bfp">PUMP</div>
        <div @click="goAnnaka_glass">GAUGE GLASS</div>
        <div @click="goCaleffi_5334">P.R.V</div>
        <div @click="goHp_valve3">VALVES</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: false,
      items: false,
    };
  },
  methods: {
    goHome() {
      this.info = false;
      this.items = false;
      this.$router.push({ name: "home" }).catch(() => {});
    },
    goCEOment() {
      this.info = false;
      this.items = false;
      this.$router.push({ name: "ceoment" }).catch(() => {});
    },
    goFindLoad() {
      this.info = false;
      this.items = false;
      this.$router.push({ name: "findload" }).catch(() => {});
    },
    info_btn() {
      this.info = !this.info;
      this.items = false;
    },
    items_btn() {
      this.items = !this.items;
      this.info = false;
    },
    goDanfoss() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/danfoss" }).catch(() => {});
    },
    goDanfoss_nozzle() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/danfoss_nozzle" }).catch(() => {});
    },
    goDanfoss_bfp() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/danfoss_bfp" }).catch(() => {});
    },
    goHago() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/hago" }).catch(() => {});
    },
    goAnnaka() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/annaka" }).catch(() => {});
    },
    goAnnaka_glass() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/annaka_glass" }).catch(() => {});
    },
    goHp() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/hp_technik" }).catch(() => {});
    },
    goNippon() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/nippon" }).catch(() => {});
    },
    goHp_valve3() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/hp_valve3" }).catch(() => {});
    },
    goCaleffi() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/caleffi" }).catch(() => {});
    },
    goCaleffi_5334() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/caleffi_5334" }).catch(() => {});
    },
    goCaleffi_5334() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/caleffi_5334" }).catch(() => {});
    },
    goSuntec() {
      this.info = false;
      this.items = false;
      this.$router.push({ path: "/items/suntec" }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  padding: 1px 0;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  position: fixed;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.header img {
  width: 220px;
  cursor: pointer;
}
.header div {
  margin-left: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  color: #3e3a39;
  transition: border-color 0.4s, background 0.4s, color 0.4s, opacity 0.4s;
}
.header div:hover,
.side-bar div:hover {
  color: #337ab7;
}
.side-bar {
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.side-bar div {
  margin-right: 25px;
  font-size: 16px;
  color: #191919;
  cursor: pointer;
  transition: border-color 0.4s, background 0.4s, color 0.4s, opacity 0.4s;
}
</style>
