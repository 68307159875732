<template>
  <div id="main">
    <div class="banner"><img :src="bannerURL" alt="" /></div>
    <div class="wrap">
      <div class="nav">
        <div class="nav_title">
          <h2>제품소개</h2>
        </div>
        <div class="item_listbox">
          <h3 @click="goDanfoss">DANFOSS</h3>
          <div @click="goDanfoss_dpf">매연저감장치</div>
          <div @click="goDanfoss_nozzle">OIL NOZZLE</div>
          <div @click="goDanfoss_bfp">GEARPUMP - BFP, RSA, RSH</div>
          <div @click="goDanfoss_ebi4">EBI4 Series</div>
          <div @click="goDanfoss_mbs">MBS3000 and MBS3050</div>
        </div>
        <div class="item_listbox">
          <h3 @click="goSuntec">SUNTEC</h3>
          <div @click="goSuntec_an">PUMP AN</div>
          <div @click="goSuntec_as">PUMP AS</div>
          <div @click="goSuntec_aj">PUMP AJ</div>
          <div @click="goSuntec_j">PUMP J</div>
          <div @click="goSuntec_e">PUMP E</div>
          <div @click="goSuntec_ta">PUMP TA</div>
          <div @click="goSuntec_tar">PUMP TAR</div>
          <div @click="goSuntec_t">PUMP T</div>
          <div @click="goSuntec_tv">TV VALVE</div>
        </div>
        <div class="item_listbox">
          <h3 @click="goHago">HAGO</h3>
          <div @click="goHago_nozzle">NOZZLE</div>
        </div>
        <div class="item_listbox" style="margin-bottom: 20px">
          <h3 @click="goHP">HP-TECHNIK</h3>
          <div @click="goHp_ponpump">Series PON PUMP</div>
          <div @click="goHp_uhepump">Series UHE PUMP</div>
          <div @click="goHp_valve1">SERIES B-PRO VALVE</div>
          <div @click="goHp_valve2">
            PRESSURE-REGULATING VALVE WITH MODULATING KIT
          </div>
          <div @click="goHp_valve3">OVERFLOW VALVES WITH THREAD CONNECTION</div>
        </div>
        <div class="item_listbox" style="margin-bottom: 20px">
          <h3 @click="goAnnaka">ANNAKA</h3>
          <div @click="goAnnaka_glass">GAUGE GLASS</div>
        </div>
        <div class="item_listbox" style="margin-bottom: 20px">
          <h3 @click="goCaleffi">CALEFFI</h3>
          <div @click="goCaleffi_5334">압력조정기</div>
          <div @click="goCaleffi_5020">에어벤트</div>
        </div>
        <div class="item_listbox" style="margin-bottom: 20px">
          <h3 @click="goNippon">NIPPON</h3>
          <div @click="goNippon_vsc">VSC PUMP</div>
          <div @click="goNippon_vskx">VSKX PUMP</div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.paramsID(this.$route.name);
  },
  mounted() {},
  data() {
    return {
      bannerURL: "",
    };
  },
  methods: {
    paramsID(name) {
      if (
        name == "danfoss" ||
        name == "danfoss_nozzle" ||
        name == "danfoss_dpf" ||
        name == "danfoss_bfp" ||
        name == "danfoss_ebi4" ||
        name == "danfoss_mbs"
      ) {
        this.bannerURL = require("../img/partner/danfoss_banner.jpg");
        window.scrollTo(0, 0);
      } else if (name == "hago" || name == "hago_nozzle") {
        this.bannerURL = require("../img/partner/hago_banner.jpg");
        window.scrollTo(0, 0);
      } else if (name == "annaka" || name == "annaka_glass") {
        this.bannerURL = require("../img/partner/annaka_banner.jpg");
        window.scrollTo(0, 0);
      } else if (
        name == "hp_technik" ||
        name == "hp_ponpump" ||
        name == "hp_uhepump" ||
        name == "hp_valve1" ||
        name == "hp_valve2" ||
        name == "hp_valve3"
      ) {
        this.bannerURL = require("../img/partner/hp_banner.jpg");
        window.scrollTo(0, 0);
      } else if (
        name == "nippon" ||
        name == "nippon_vsc" ||
        name == "nippon_vskx"
      ) {
        this.bannerURL = require("../img/partner/nippon_banner.jpg");
        window.scrollTo(0, 0);
      } else if (
        name == "caleffi" ||
        name == "caleffi_5334" ||
        name == "caleffi_5020"
      ) {
        this.bannerURL = require("../img/partner/caleffi_banner.jpg");
        window.scrollTo(0, 0);
      } else if (
        name == "suntec" ||
        name == "suntec_an" ||
        name == "suntec_as" ||
        name == "suntec_aj" ||
        name == "suntec_j" ||
        name == "suntec_e" ||
        name == "suntec_ta" ||
        name == "suntec_tar" ||
        name == "suntec_t" ||
        name == "suntec_tv"
      ) {
        this.bannerURL = require("../img/partner/suntec_banner.jpg");
        window.scrollTo(0, 0);
      }
    },
    goDanfoss_nozzle() {
      this.$router.push({ path: "/items/danfoss_nozzle" }).catch(() => {});
    },
    goDanfoss() {
      this.$router.push({ path: "/items/danfoss" }).catch(() => {});
    },
    goDanfoss_dpf() {
      this.$router.push({ path: "/items/danfoss_dpf" }).catch(() => {});
    },
    goDanfoss_bfp() {
      this.$router.push({ path: "/items/danfoss_bfp" }).catch(() => {});
    },
    goDanfoss_ebi4() {
      this.$router.push({ path: "/items/danfoss_ebi4" }).catch(() => {});
    },
    goDanfoss_mbs() {
      this.$router.push({ path: "/items/danfoss_mbs" }).catch(() => {});
    },
    goHago() {
      this.$router.push({ path: "/items/hago" }).catch(() => {});
    },
    goHago_nozzle() {
      this.$router.push({ path: "/items/hago_nozzle" }).catch(() => {});
    },
    goAnnaka() {
      this.$router.push({ path: "/items/annaka" }).catch(() => {});
    },
    goAnnaka_glass() {
      this.$router.push({ path: "/items/annaka_glass" }).catch(() => {});
    },
    goHP() {
      this.$router.push({ path: "/items/hp_technik" }).catch(() => {});
    },
    goHp_ponpump() {
      this.$router.push({ path: "/items/hp_ponpump" }).catch(() => {});
    },
    goHp_uhepump() {
      this.$router.push({ path: "/items/hp_uhepump" }).catch(() => {});
    },
    goHp_valve1() {
      this.$router.push({ path: "/items/hp_valve1" }).catch(() => {});
    },
    goHp_valve2() {
      this.$router.push({ path: "/items/hp_valve2" }).catch(() => {});
    },
    goHp_valve3() {
      this.$router.push({ path: "/items/hp_valve3" }).catch(() => {});
    },
    goNippon() {
      this.$router.push({ path: "/items/nippon" }).catch(() => {});
    },
    goNippon_vsc() {
      this.$router.push({ path: "/items/nippon_vsc" }).catch(() => {});
    },
    goNippon_vskx() {
      this.$router.push({ path: "/items/nippon_vskx" }).catch(() => {});
    },
    goCaleffi() {
      this.$router.push({ path: "/items/caleffi" }).catch(() => {});
    },
    goCaleffi_5334() {
      this.$router.push({ path: "/items/caleffi_5334" }).catch(() => {});
    },
    goCaleffi_5020() {
      this.$router.push({ path: "/items/caleffi_5020" }).catch(() => {});
    },
    goSuntec() {
      this.$router.push({ path: "/items/suntec" }).catch(() => {});
    },
    goSuntec_an() {
      this.$router.push({ path: "/items/suntec_an" }).catch(() => {});
    },
    goSuntec_as() {
      this.$router.push({ path: "/items/suntec_as" }).catch(() => {});
    },
    goSuntec_aj() {
      this.$router.push({ path: "/items/suntec_aj" }).catch(() => {});
    },
    goSuntec_j() {
      this.$router.push({ path: "/items/suntec_j" }).catch(() => {});
    },
    goSuntec_e() {
      this.$router.push({ path: "/items/suntec_e" }).catch(() => {});
    },
    goSuntec_ta() {
      this.$router.push({ path: "/items/suntec_ta" }).catch(() => {});
    },
    goSuntec_tar() {
      this.$router.push({ path: "/items/suntec_tar" }).catch(() => {});
    },
    goSuntec_t() {
      this.$router.push({ path: "/items/suntec_t" }).catch(() => {});
    },
    goSuntec_tv() {
      this.$router.push({ path: "/items/suntec_tv" }).catch(() => {});
    },
  },
  watch: {
    $route(to, form) {
      if (to.path !== form.path) this.paramsID(this.$route.name);
    },
  },
};
</script>

<style scoped>
#main {
  padding-top: 65px;
  width: 1080px;
  margin: 0 auto;
  margin-bottom: 150px;
}
.banner {
  width: 1080px;
  height: 300px;
  margin-bottom: 40px;
}
.banner img {
  width: 1080px;
  height: 300px;
  border-radius: 12px;
}
.wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.nav {
  width: 200px;
  border: 1px solid #dbdbdb;
  margin-right: 70px;
}
.nav_title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  border-bottom: 1px solid #dbdbdb;
}
.item_listbox {
  padding: 0 20px;
  margin-top: 20px;
}
.item_listbox h3 {
  padding: 8px 5px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}
.item_listbox h3:hover {
  background: rgba(255, 0, 0, 0.8);
  color: #fff;
}
.item_listbox div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding: 8px 10px;
  background: rgba(255, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}
.item_listbox div:hover {
  background-color: #fff;
  color: red;
}
</style>
