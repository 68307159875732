<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>DANFOSS</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          매연절감장치
        </div>
      </div>
    </div>
    <div class="contents">
      <div>
        <div>
          <h2>DPF System</h2>
          <div>
            쾌적한 대기환경 조성을 위해 오염물질을 다량 배출하는 노후경유차,
            노후건설기계 등을 대상으로<br />
            배출가스 저감장치 부착, 조기폐차 등에 소요되는 비용의 일부를 정부가
            보조하는 사업입니다.<br />
            (주)동주티엔씨는 배출가스 저감장치 부착 사업에 적극 참여 중입니다.
          </div>
        </div>
        <img
          src="@/img/상세페이지/댄포스/매연저감장치.png"
          alt=""
          style="border: 1px solid #dbdbdb; padding: 15px; margin-left: 30px"
        />
      </div>
      <div>
        <img
          src="@/img/상세페이지/댄포스/매연저감장치1.png"
          alt=""
          style="border: 1px solid #dbdbdb; padding: 15px; margin-right: 30px"
        />
        <div>
          <h2>Hybrid DPF System</h2>
          <div>
            복합재생장치는 DPF를 장착하여 배출가스 중 PM을 98%이상
            저감가능하며,<br />
            경유버너를 사용하여 배출가스 온도 확보가 어려운 저속/저부하
            조건에서도 강제 재생방식으로 재생이 가능합니다.
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>Passive DPF system</h2>
          <div>
            엔진의 배기열(배기가스 열)을 이용하여 엔진에서 배출되는 PM을 DPF
            필터에 포집하면서 연속적으로 필터를 재생시키며 기존 제품보다 낮은
            온도조건에서 적용이 가능한 장치입니다.（DOC와 DPF로 구성된 장치로
            엔진의 배기가스 열을 이용하여 DOC에서 HC, CO를 저감하고 PM을 DPF에
            포집하여 산화시키는 장치이다.)
          </div>
        </div>
        <img
          src="@/img/상세페이지/댄포스/매연저감장치2.jpg"
          alt=""
          style="margin-left: 30px"
        />
      </div>
      <div>
        <img
          src="@/img/상세페이지/댄포스/매연저감장치3.jpg"
          alt=""
          style="margin-right: 30px"
        />
        <div>
          <h2>Active DPF System</h2>
          <div>
            버너 + DPF로 구성되어 DPF 에 포집된 PM을 버너로 강제 재생하는 방식의
            장치
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.contents > div > img {
  width: 350px;
}
.contents > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-bottom: 100px;
}
.contents > div > div > h2 {
  color: #222222;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 1.35;
  font-size: 38px;
  margin-bottom: 25px;
}
.contents > div > div > div {
  line-height: 1.65em;
  color: #686868;
}
</style>
