<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>SUNTEC</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          PUMP AJ
        </div>
      </div>
      <a href="/file/suntec/PUMP_AJ.pdf" target="_blank">
        <span style="font-size: 15px">PUMP_AJ.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="box1">
        <img
          src="@/img/상세페이지/suntec/목록/suntec_aj.png"
          alt=""
          style="width: 350px"
        />
        <div>
          <p>
            SUNTEC AJ 오일 펌프는 컷오프* 기능이 있는 압력 조절 밸브가 포함된
            기본 모델입니다.
          </p>
          <img
            src="@/img/상세페이지/suntec/suntec_an2.png"
            alt=""
            style="width: 330px; margin-top: 15px"
          />
        </div>
      </div>
      <div class="box2">
        <div>
          <p>
            기어 세트는 내장된 필터를 통해 탱크에서 오일을 끌어와 노즐 라인의
            오일 압력을 조절하는 밸브로 전달합니다. <br />노즐 라인을 통과하지
            않는 모든 오일은 밸브를 통과하여 2개 파이프 설치의 복귀 라인으로
            돌아가거나, 1개 파이프 설치인 경우 기어 세트의 흡입 포트로 다시
            돌아갑니다. 이 경우 바이패스 플러그를 진공 게이지 포트에서 제거하고
            강철 플러그와 와셔로 밀봉된 리턴 포트를 제거해야 합니다.
            <br /><br />밸브에는 다음과 같은 차단 기능*도 있습니다.<br />
            기어 설정 속도가 증가하는 시동 기간 동안 모든 오일은 피스톤의 블리드
            슬롯을 통과하여 복귀 방향으로 돌아갑니다. <br />속도가 특정 값에
            도달하고 흐름이 더 이상 이 블리드 슬롯을 통과할 수 없으면 압력이
            급격히 증가하여 밸브 스프링 힘을 극복하고 밸브가 열립니다.
            <br />정지 시퀀스 동안 기어 세트 속도가 느려지고 기어 세트 용량이
            블리드 슬롯 흐름보다 낮을 때 밸브가 닫힙니다. <br />컷온 및 컷오프
            속도는 기어 세트 크기와 설정 압력에 따라 달라집니다.<br /><br />
            <strong style="font-weight: 600; color: black">블리드</strong><br />
            2관 작동 시 블리딩은 자동으로 이루어지지만 압력계 포트의 플러그를
            느슨하게 하면 가속화될 수 있습니다.<br />
            한 번의 파이프 작업에서는 시스템에서 공기를 빼내기 위해 압력 포트를
            열어야 합니다. *AJ 1002 모델은 노즐 바이패스 구멍으로 인해 컷오프
            기능이 없습니다. 차단은 외부 솔레노이드 밸브에 의해 제공되어야
            합니다.
          </p>
        </div>
        <div>
          <img
            src="@/img/상세페이지/suntec/suntec_aj1.png"
            style="width: 250px; margin-left: 15px"
          />
        </div>
      </div>
      <img
        src="@/img/상세페이지/suntec/suntec_aj2.png"
        style="width: 840px; margin-top: 60px"
      />
    </div>
    <div class="data_sheet">
      <a href="/file/suntec/PUMP_AJ.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}

.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box1 > div > p,
.box2 > div > p {
  color: #686868;
  line-height: 1.7;
}
.box2 {
  margin-top: 55px;
  display: flex;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
