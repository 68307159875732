<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>DANFOSS</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          Oil Nozzle type OD
        </div>
      </div>
      <a href="/file/danfoss_nozzle.pdf" target="_blank">
        <span style="font-size: 15px">OIL_NOZZLE.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div>
        <img
          src="@/img/상세페이지/댄포스/댄포스노즐.jpg"
          alt=""
          style="border: 1px solid #dbdbdb"
        />
      </div>
      <div>
        <img src="@/img/상세페이지/댄포스/댄포스노즐2.jpg" alt="" />
      </div>
      <div>
        <img src="@/img/상세페이지/댄포스/댄포스노즐3.jpg" alt="" />
      </div>
    </div>
    <div class="contents2">
      <p>
        댄포스는 표준 버너 시스템을 위해 <br />황동 및 강철 재질의 다양한 오일
        노즐을 제공합니다. <br />당사는 각기 다른 업계의 다양한 운전 영역, 매질
        및 <br />배출물질에 적합한 일련의 특수 노즐을 제공합니다.
        <br /><br />당사는 EN 293 및 EN 299에 따라 노즐을 생산합니다.
        <br />품질을 보장하기 위해 각각의 노즐은 용량 및 분사 패턴에 맞게<br />
        100% 전자적으로 시험을 거칩니다. <br /><br />위험을 방지하고 최상의
        성능을 제공하기 위해서는 <br />마치 자신의 차량을 관리하듯이 오일 버너도
        관리해야 합니다. <br />안정적이면서도 최적의 효율으로 구동하도록
        보장하기 위해서는 <br />정기적인 점검 및 서비스가 필수입니다.
        <br />댄포스는 환경에 미치는 영향 및 운전 성능을 위해 <br />1년에 한 번
        노즐을 교체하라고 권고합니다.
      </p>
      <img src="@/img/상세페이지/댄포스/댄포스배너.jpg" alt="" />
      <div
        style="
          line-height: 36px;
          font-size: 24px;
          color: black;
          font-weight: bold;
        "
      >
        올해에는 오일 노즐을<br />교체하셨습니까?
      </div>
      <iframe
        width="840"
        height="515"
        src="https://www.youtube.com/embed/IKj_CbRpgio?si=yec5E9u0G_4U6_0S"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div class="data_sheet">
      <a href="/file/danfoss_nozzle.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oilnozzle_pdf: "../document/danfoss_nozzle.pdf",
    };
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.contents {
  width: 840px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.contents img {
  width: 240px;
}
.contents > div {
  width: 240px;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}
.contents > div:last-child {
  margin-right: 0px;
}
.contents2 {
  margin-top: 60px;
  position: relative;
}
.contents2 img {
  width: 840px;
  box-sizing: border-box;
  opacity: 0.7;
  margin-bottom: 20px;
  border-radius: 4px;
}
.contents2 p {
  background-image: "@/img/상세페이지/댄포스/댄포스노즐3.jpg";
  width: 500px;
  text-align: left;
  color: #686868;
  /* color: #fff; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
  margin-bottom: 40px;
}
.contents2 div {
  width: 320px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  bottom: 580px;
  left: 250px;
  box-sizing: border-box;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
