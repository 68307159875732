<template>
  <div class="danfoss">
    <div class="title">
      <h2>오시는 길</h2>
    </div>
    <div class="contents">
      <img src="@/img/info/map.png" alt="" style="width: 840px" />
      <div>
        <div>
          오시는길 : 서울시 구로구 구로동 212-30 에이스트윈타워 2차 609호
        </div>
        <div>버스 이용시 : 5536번, 5616번</div>
        <div style="margin-bottom: 0">
          지하철 이용시 : 남구로역 2번 출구, 구로디지털단지역 3번 출구
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.2935101545804!2d126.89419181722083!3d37.483892529600574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e2376f11fbf%3A0xcd4bc3c4fff3816f!2z7ISc7Jq47Yq567OE7IucIOq1rOuhnOq1rCDrlJTsp4DthLjroZwgMjcz!5e0!3m2!1sko!2skr!4v1697516152293!5m2!1sko!2skr"
        width="840"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.contents > div {
  width: 840px;
  border: 1px solid #dbdbdb;
  margin: 40px 0;
  padding: 30px;
  box-sizing: border-box;
}
.contents > div > div {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 34px;
  color: #333;
  letter-spacing: -1px;
}
</style>
