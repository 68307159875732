<template>
  <div class="danfoss">
    <bfp_popup v-if="bfp_pop == true"></bfp_popup>
    <div class="title">
      <div>
        <h2>DANFOSS</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          GEAR PUMP - BFP,RSA,RSH
        </div>
      </div>
      <div class="documentBtn" @click="documentBtn">문서</div>
    </div>
    <div class="contents">
      <div class="imgbox">
        <img src="@/img/상세페이지/댄포스/댄포스펌프.jpg" alt="" />
        <div>
          댄포스는 다이아몬드 시리즈(1단계 및 2단계)를 통해 상업용 및 주거용
          소규모 시장에 완벽한 펌프를 제공합니다. 댄포스 다이아몬드 서비스
          펌프는 다이아몬드 경도와 탄소 윤활성의 조합 덕분에 뛰어난 견고성,
          내구성 및 강도를 제공하는 다이아몬드 유사 탄소 기술로 제작되었습니다.
          그 결과, 매우 까다로운 애플리케이션에서도 10배 이상 오랜 수명을
          제공합니다. 강력한 내구성을 제공하는 다이아몬드 코일은 이전의 서비스
          코일과 비교했을 때 충돌 시험에서 5배 이상의 내구성을 보입니다. RSA 및
          RSH 펌프는 피스톤 조절기, 차단 기능 및 내장 필터를 갖추고 있으며 경유
          및 중유에 사용합니다.
        </div>
      </div>
      <div class="mentbox">
        <p>
          최상의 성능을 제공하기 위해서는 마치 자신의 차량을 관리하듯이 오일
          버너를 관리해야 하며 노즐, 필터 및 코일과 같은 컴포넌트의 교체를
          포함하여 정기적인 서비스를 받아야 합니다.<br /><br />
        </p>
        <p>
          <strong style="font-weight: 600"
            >중부하 조건에서도 오랜 수명 제공</strong
          ><br />
          오일 버너 애플리케이션은 최근 수년간 보다 높은 압력에서 작동하도록
          변해왔으며 소음 감소를 위해서나 에너지 절약을 위해서 보다 강력한
          절연을 필요로 했습니다. 그 결과, 펌프의 작동 조건이 더욱
          까다로워졌습니다. 이러한 이유 때문에 당사는 업계의 가장 높은
          요구사항을 충족할 수 있도록 모든 서비스 펌프를 버전 2.0으로
          업그레이드했습니다.
        </p>
      </div>
      <div class="goodpoint">
        <h2>기능 및 장점</h2>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>
            중부하 조건과 같이 매우 까다로운 애플리케이션에서도 10배 이상 오랜
            수명 제공.
          </div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>추가적인 내구성을 제공하는 다이아몬드 코일 장착.</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>이중 보증.</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>기계적인 샤프트 씰.</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>누유 및 오일 냄새 없음.</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>
            펌프 필터를 45초만에 교체할 수 있음(기존 펌프에서는 10분 소요).
          </div>
        </div>
      </div>
      <iframe
        width="840"
        height="515"
        src="https://www.youtube.com/embed/lG8UUTfOJd4?si=kNjI3OsnYA5lJ4d-"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        style="margin-top: 40px"
      ></iframe>
    </div>
    <div class="data_sheet" @click="documentBtn">제품 카탈로그 다운로드</div>
  </div>
</template>

<script>
import bfp_popup from "./danfoss_bfp_document.vue";
export default {
  components: {
    bfp_popup,
  },
  computed: {
    bfp_pop() {
      return this.$store.state.danfoss_bfp_popup;
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    documentBtn() {
      this.$store.commit("danfoss_bfp_popup");
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.documentBtn {
  width: 100px;
  height: 30px;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s, background 0.2s;
}
.documentBtn:hover {
  background: red;
  color: #fff;
}
.contents {
  width: 840px;

  margin-top: 50px;
}
.imgbox {
  display: flex;
  align-items: flex-start;
}
.imgbox img {
  width: 400px;
  margin-right: 25px;
  border-radius: 4px;
}
.imgbox div {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.mentbox {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
  margin-top: 40px;
}
.goodpoint {
  margin-top: 100px;
}
.goodpoint h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
}
.goodpoint > div {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.goodpoint > div > img {
  width: 20px;
  margin-right: 10px;
}
.goodpoint > div > div {
  color: #191919;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 20px;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
</style>
