<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>NIPPON</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          VSC PUMP
        </div>
      </div>
      <a href="/file/NIPPON_VSC.pdf" target="_blank">
        <span style="font-size: 15px">NIPPON_VSC.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <img src="@/img/상세페이지/nippon/vsc0.png" alt="" />
      <img src="@/img/상세페이지/nippon/vsc1.png" alt="" />
      <img src="@/img/상세페이지/nippon/vsc2.jpg" alt="" />
    </div>
    <div class="data_sheet">
      <a href="/file/NIPPON_VSC.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}

.contents img {
  width: 840px;
  border-radius: 4px;
  margin-bottom: 60px;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
