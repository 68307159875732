<template>
  <div id="app">
    <nav-bar></nav-bar>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <footerBar></footerBar>
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import footerBar from "@/components/footerBar.vue";
export default {
  name: "App",
  components: { navBar, footerBar },
  mounted() {},
};
</script>

<style>
@import "@/assets/ResetCSS.css";
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
