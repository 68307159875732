<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>SUNTEC</h2>
      </div>
    </div>
    <div class="contents">
      <div @click="goSuntec_an">
        <img src="@/img/상세페이지/suntec/목록/suntec_an.png" alt="" />
        <div>PUMP AN</div>
      </div>
      <div @click="goSuntec_as">
        <img src="@/img/상세페이지/suntec/목록/suntec_as.png" alt="" />
        <div>PUMP AS</div>
      </div>
      <div @click="goSuntec_aj" style="margin-right: 0">
        <img src="@/img/상세페이지/suntec/목록/suntec_aj.png" alt="" />
        <div>PUMP AJ</div>
      </div>
      <div @click="goSuntec_j">
        <img
          src="@/img/상세페이지/suntec/목록/suntec_j.png"
          alt=""
          style="width: 150px; margin-bottom: 15px"
        />
        <div>PUMP J</div>
      </div>
      <div @click="goSuntec_e">
        <img src="@/img/상세페이지/suntec/목록/suntec_e.png" alt="" />
        <div>PUMP E</div>
      </div>
      <div @click="goSuntec_ta" style="margin-right: 0">
        <img src="@/img/상세페이지/suntec/목록/suntec_ta.png" alt="" />
        <div>PUMP TA</div>
      </div>
      <div @click="goSuntec_tar">
        <img src="@/img/상세페이지/suntec/목록/suntec_tar.png" alt="" />
        <div>PUMP TAR</div>
      </div>
      <div @click="goSuntec_t">
        <img src="@/img/상세페이지/suntec/목록/suntec_t.png" alt="" />
        <div>PUMP T</div>
      </div>
      <div @click="goSuntec_tv" style="margin-right: 0">
        <img src="@/img/상세페이지/suntec/목록/suntec_tv.png" alt="" />
        <div>TV VALVE</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goSuntec_an() {
      this.$router.push({ path: "/items/suntec_an" }).catch(() => {});
    },
    goSuntec_as() {
      this.$router.push({ path: "/items/suntec_as" }).catch(() => {});
    },
    goSuntec_aj() {
      this.$router.push({ path: "/items/suntec_aj" }).catch(() => {});
    },
    goSuntec_j() {
      this.$router.push({ path: "/items/suntec_j" }).catch(() => {});
    },
    goSuntec_e() {
      this.$router.push({ path: "/items/suntec_e" }).catch(() => {});
    },
    goSuntec_ta() {
      this.$router.push({ path: "/items/suntec_ta" }).catch(() => {});
    },
    goSuntec_tar() {
      this.$router.push({ path: "/items/suntec_tar" }).catch(() => {});
    },
    goSuntec_t() {
      this.$router.push({ path: "/items/suntec_t" }).catch(() => {});
    },
    goSuntec_tv() {
      this.$router.push({ path: "/items/suntec_tv" }).catch(() => {});
    },
    goDanfoss_dpf() {},
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contents {
  width: 840px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-top: 50px;
}
.contents > div {
  width: 250px;
  height: 250px;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: border 0.2s, color 0.2s;
}
.contents > div:hover {
  border: 1px solid red;
}
.contents > div > div {
  color: #686868;
}
.contents div img {
  width: 200px;
  margin-bottom: 30px;
  padding: 15px;
}
</style>
