<template>
  <div class="danfoss">
    <bfp_popup v-if="bfp_pop == true"></bfp_popup>
    <div class="title">
      <div>
        <h2>DANFOSS</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          EBI4 Series
        </div>
      </div>
      <a href="/file/danfoss_EBI4.pdf" target="_blank">
        <span style="font-size: 15px">danfoss_EBI4.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="imgbox">
        <img src="@/img/상세페이지/댄포스/목록/danfoss_ebi4.jpg" alt="" />
        <div>
          주위 온도가 낮은 경우, 신속한 오일 버너 점화가 어려울 수 있습니다.
          당사는 그 해결책으로 일련의 고성능 점화 장치, EBI4를 출시했으며 이
          장치는 저온에서도 높은 점화력을 제공합니다. 점화 장치 EBI4 시리즈는
          광범위한 가정용 버너 및 경상업용 가스 또는 오일 버너에 적합하고
          현대적인 오일 및 가스 버너용 점화 장치의 새로운 표준을 제시하고 있으며
          매우 잘 알려진 댄포스 EBI 시리즈의 매우 가치 있는 후속 제품으로 자리
          잡았습니다. RoHs 및 WEEE 규정뿐만 아니라 EN 55014-1 및 EN 5514-2에
          따른 EMC를 준수합니다.
        </div>
      </div>

      <div class="goodpoint">
        <h2>기능 및 장점</h2>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>전압 부족 시에도 높은 점화 성능.</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>그을음 감소로 4-10%의 오일 소비 감소.</div>
        </div>
        <div>
          <img src="@/img/상세페이지/댄포스/check.jpg" alt="" />
          <div>써멀 과부하에 대비한 높은 견고성.</div>
        </div>
      </div>
      <iframe
        width="840"
        height="515"
        src="https://www.youtube.com/embed/3gHHjIvu9xk?si=s2OynLJ1GbsMgCFh"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        style="margin-top: 60px"
      ></iframe>
    </div>
    <div class="data_sheet">
      <a href="/file/danfoss_EBI4.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
import bfp_popup from "./danfoss_bfp_document.vue";
export default {
  components: {
    bfp_popup,
  },
  computed: {
    bfp_pop() {
      return this.$store.state.danfoss_bfp_popup;
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    documentBtn() {
      this.$store.commit("danfoss_bfp_popup");
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.documentBtn {
  width: 100px;
  height: 30px;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s, background 0.2s;
}
.documentBtn:hover {
  background: red;
  color: #fff;
}
.contents {
  width: 840px;

  margin-top: 50px;
}
.imgbox {
  display: flex;
  align-items: flex-start;
}
.imgbox img {
  width: 400px;
  margin-right: 25px;
  border-radius: 4px;
}
.imgbox div {
  color: #686868;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
}

.goodpoint {
  margin-top: 100px;
}
.goodpoint h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 30px;
}
.goodpoint > div {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.goodpoint > div > img {
  width: 20px;
  margin-right: 10px;
}
.goodpoint > div > div {
  color: #191919;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 20px;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
