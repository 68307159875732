<template>
  <div class="danfoss">
    <div class="title">
      <h2>CEO 인사말</h2>
    </div>
    <div class="contents">
      <p>
        저희 <span>(주)동주티엔씨</span>를 방문하여 주셔서 감사합니다.<br /><br />
        저희 동주티엔씨는 40여년동안 보일러 부품 업계의 선두주자로
        <span>노즐, 펌프, 버너, 게이지글래스</span>등을 직수입 판매하고
        있습니다.<br /><br />세계적인 기업 DANFOSS의 오일펌프(BFP, RSA 제품군)와
        노즐(Danfoss Nozzle, Hago Nozzle) 그리고 Electronic lgnition(EBI)를
        직수입 공급하고 있습니다.<br /><br />
        또한, GAUGE GLASS - ANNAKA의 한국 공식 공급사이며, 일본 VSC 펌프와
        감압변을 취급하고 있습니다.<br /><br />지난 40여년 동안 한결같이
        보내주신 고객 여려분의 신뢰와 성원에 언제나 보답 할 수 있도록 노력하는
        저희가 되겠습니다. 또한, 제품의 문의를 주시면, 빠르고 정확하게 답변
        드리겠습니다. <br /><br />앞으로도 저희 홈페이지를 자주 찾아주시고,
        각별한 애정과 관심을 보내주시길 바랍니다.<br /><br />감사합니다.
      </p>
      <img
        src="@/img/info/ceoment.png"
        alt=""
        style="width: 250px; margin-left: 600px; margin-top: 80px"
      />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.contents P {
  font-size: 20px;
  line-height: 34px;
  color: #333;
  letter-spacing: -1px;
}
.contents span {
  color: #5286c1;
}
</style>
