<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>CALEFFI</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          에어벤트
        </div>
      </div>
    </div>
    <div class="contents">
      <img src="@/img/상세페이지/caleffi/5020.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}

.contents img {
  width: 840px;
  border-radius: 4px;
  margin-bottom: 60px;
}
</style>
