<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>SUNTEC</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          PUMP T
        </div>
      </div>
      <a href="/file/suntec/PUMP_T.pdf" target="_blank">
        <span style="font-size: 15px">PUMP_T.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="box1">
        <img
          src="@/img/상세페이지/suntec/목록/suntec_t.png"
          alt=""
          style="width: 250px"
        />
        <div>
          <p>
            SUNTEC T 오일 펌프는 고용량의 경유 또는 중유를 사용하는 산업용 난방
            응용 분야를 위해 특별히 설계되었습니다. 냉간 시동을 더 쉽게 하기
            위해 예열기 위치가 장착되어 있습니다.
          </p>
          <img
            src="@/img/상세페이지/suntec/suntec_t1.png"
            alt=""
            style="width: 250px; margin-top: 15px"
          />
        </div>
      </div>
      <div class="box2">
        <div>
          <p>
            기어 세트는 탱크에서 오일을 끌어와 오일 압력을 조절하는 외부 밸브로
            전달합니다.<br /><br />
            <strong style="font-weight: 600; color: black">블리드</strong><br />
            시스템에서 공기가 빠져나갈 때까지 압력 게이지 포트의 플러그를
            느슨하게 풀어야 합니다.
            <br /><br />
            <strong style="font-weight: 600; color: black">참고</strong><br />
            고압과 샤프트 씰 사이에 삽입된 바이패스 플러그는 펌프 회전을
            변경하기 위한 용도로만 사용됩니다. 펌프의 압력 배출구에 4mm Allen
            키를 사용하여 이 플러그가 있는지 확인하십시오. 주의: 펌프 회전
            방향을 변경하면 모든 펌프 연결도 변경됩니다.
            <br /><br />
            <strong style="font-weight: 600; color: black">예열 시설</strong
            ><br />
            점도가 높은 차가운 오일로 펌프를 시동하면 펌프와 커플링이 손상되지
            않도록 주의해야 합니다. 이러한 이유로 T 펌프 본체에는 전기 예열기를
            수용할 수 있는 구멍이 있습니다. 이 구멍은 히터 카트리지와 오일
            사이의 직접 접촉 없이 히터에서 펌프의 오일로 최대 열 전달을
            제공하도록 위치되었습니다. 펌프를 시동하기 전 일정 기간 동안 히터를
            연결해야 합니다. 적절한 온도에 도달하면 정기적인 버너 정지 동안
            펌프의 유체 오일을 유지하기 위해 스위치를 끄거나 영구적으로 켜진
            상태로 둘 수 있습니다. 오일 공급 장치, 파이프 및 필터는 별도로
            가열해야 합니다.
          </p>
        </div>
        <div>
          <img
            src="@/img/상세페이지/suntec/suntec_t2.png"
            style="width: 250px; margin-left: 15px"
          />
        </div>
      </div>
      <img
        src="@/img/상세페이지/suntec/suntec_t3.png"
        style="width: 840px; margin-top: 60px"
      />
    </div>
    <div class="data_sheet">
      <a href="/file/suntec/PUMP_T.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}

.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box1 > div > p,
.box2 > div > p {
  color: #686868;
  line-height: 1.7;
}
.box2 {
  margin-top: 55px;
  display: flex;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
