<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>SUNTEC</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          TV VALVE
        </div>
      </div>
      <a href="/file/suntec/TV_VALVE.pdf" target="_blank">
        <span style="font-size: 15px">TV_VALVE.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="box1">
        <img
          src="@/img/상세페이지/suntec/목록/suntec_tv.png"
          alt=""
          style="width: 250px"
        />
        <div>
          <p>SUNTEC TV 밸브는 압력 조절 밸브입니다.</p>
          <img
            src="@/img/상세페이지/suntec/suntec_tv1.png"
            alt=""
            style="width: 250px; margin-top: 15px"
          />
        </div>
      </div>
      <div class="box2">
        <div>
          <p>
            노즐 라인의 압력은 TV 밸브의 조정 나사로 조정됩니다. 노즐 요구
            사항을 초과하는 오일은 반환으로 버려집니다.
          </p>
        </div>
        <div>
          <img
            src="@/img/상세페이지/suntec/suntec_tv2.png"
            style="width: 250px; margin-left: 15px"
          />
        </div>
      </div>
    </div>
    <div class="data_sheet">
      <a href="/file/suntec/PUMP_AJ.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}

.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box1 > div > p,
.box2 > div > p {
  color: #686868;
  line-height: 1.7;
}
.box2 {
  margin-top: 55px;
  display: flex;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
