<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>CALEFFI</h2>
      </div>
    </div>
    <div class="contents">
      <div @click="goCaleffi_5334">
        <img
          src="@/img/상세페이지/caleffi/목록/5334.jpg"
          alt=""
          style="margin-bottom: -15px"
        />
        <div>압력조정기</div>
      </div>
      <div @click="goCaleffi_5020">
        <img
          src="@/img/상세페이지/caleffi/목록/5020.jpg"
          alt=""
          style="margin-bottom: -15px"
        />
        <div>에어벤트</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goCaleffi_5334() {
      this.$router.push({ path: "/items/caleffi_5334" }).catch(() => {});
    },
    goCaleffi_5020() {
      this.$router.push({ path: "/items/caleffi_5020" }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.contents {
  width: 840px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-top: 50px;
}
.contents > div {
  width: 250px;
  height: 250px;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: border 0.2s, color 0.2s;
}
.contents > div:hover {
  border: 1px solid red;
}
.contents > div > div {
  color: #686868;
}
.contents div img {
  width: 200px;
  margin-bottom: 30px;
  padding: 15px;
}
</style>
