<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>HP-TECHNIK</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          PRESSURE-REGULATING VALVE WITH MODULATING KIT
        </div>
      </div>
    </div>
    <div class="contents">
      <div class="box1">
        <img src="@/img/상세페이지/HP/목록/B-PRO.jpg" alt="" />
        <div>
          <h2>조절 키트가 포함된 압력 조절 밸브</h2>
          <p>
            압력 조절 밸브를 사용하면 관련 노즐 또는 버너 성능을 달성하는 데
            필요한 버너 바이패스의 오일 양이 설정됩니다.
          </p>
          <div>
            <h2>재료</h2>
            <li>
              유압 주철로 제작된 케이싱(GGG40); 피스톤, 밸브 팁, 경화강 스프링
            </li>
          </div>
          <div>
            <h2>세부</h2>
            <li>유체 매체의 최대 온도: 150 °C</li>
          </div>
        </div>
      </div>
      <img
        src="@/img/상세페이지/HP/valve2_1.png"
        alt=""
        style="
          width: 840px;
          margin-top: 20px;
          border-bottom: 1px solid #dbdbdb;
          padding-bottom: 40px;
        "
      />
      <div class="box3">
        <div class="box3_title">
          <h2>압력 설정:</h2>
          <p>
            리턴 오일은 피스톤(10)과 밸브 콘(9) 사이에 위치한 압력 조절 밸브의
            압력 챔버로 흘러 들어갑니다. 배수 측에는 피스톤(10)과 스프링(11)이
            있습니다. 이 스프링은 버너 성능에 따라 가이드 부싱에 지지되는
            샤프트에 의해 다소 압축됩니다. 스프링의 압력이 클수록 바이패스의
            압력도 커지고 그에 따라 노즐 소모도 커집니다. 기본 설정은 조절 나사
            4에 있습니다. 시계 방향 회전은 압력 증가에 해당하고 시계 반대 방향
            회전은 압력 감소에 해당합니다.<br />Positions:  4 Regulating screw |
            9 Valve cone | 10 Piston | 11 Spring
          </p>
          <img
            src="@/img/상세페이지/HP/valve2_2.gif"
            alt=""
            style="width: 840px; margin-top: 40px"
          />
        </div>
      </div>
      <div class="box3">
        <div class="box3_title">
          <h2>사이즈표</h2>

          <img
            src="@/img/상세페이지/HP/valve2_3.png"
            alt=""
            style="width: 840px; margin-top: 40px"
          />
          <p>
            설치, 작동 및 유지 관리에 대해서는 각 장비와 함께 제공되는 작동
            지침을 따르십시오.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}
.box1 img {
  width: 300px;
  border-radius: 4px;
  margin-right: 30px;
}
.box1 > div > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
}
.box1 > div > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box2 {
  width: 840px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box1 > div > div > h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-bottom: 15px;
  margin-top: 10px;
}
.box1 > div > div > li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}
.box3,
.box4 {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
}
.box3_title h2,
.box4 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #f4762b;
  margin-top: 40px;
}
.box3_title p {
  font-size: 16px;
  color: #686868;
  margin-top: 20px;
}
</style>
