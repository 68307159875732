import Vue from "vue";
import VueRouter from "vue-router";

import itemsView from "@/views/itemsView.vue";
import infoView from "@/views/infoView.vue";

import danfoss_nozzle from "@/components/danfoss/danfoss_nozzle.vue";
import danfoss from "@/components/danfoss/danfoss.vue";
import danfoss_dpf from "@/components/danfoss/danfoss_dpf.vue";
import danfoss_bfp from "@/components/danfoss/danfoss_bfp.vue";
import danfoss_ebi4 from "@/components/danfoss/danfoss_ebi4.vue";
import danfoss_mbs from "@/components/danfoss/danfoss_mbs.vue";
import hago from "@/components/hago/hago.vue";
import hago_nozzle from "@/components/hago/hago_nozzle.vue";
import annaka from "@/components/annaka/annaka.vue";
import annaka_glass from "@/components/annaka/annaka_glass.vue";
import hp_technik from "@/components/HP-TECHNIK/HP-TECHNIK.vue";
import hp_ponpump from "@/components/HP-TECHNIK/hp_ponpump.vue";
import hp_uhepump from "@/components/HP-TECHNIK/hp_uhepump.vue";
import hp_valve1 from "@/components/HP-TECHNIK/hp_valve1.vue";
import hp_valve2 from "@/components/HP-TECHNIK/hp_valve2.vue";
import hp_valve3 from "@/components/HP-TECHNIK/hp_valve3.vue";
import nippon from "@/components/nippon/nippon.vue";
import nippon_vsc from "@/components/nippon/nippon_vsc.vue";
import nippon_vskx from "@/components/nippon/nippon_vskx.vue";
import caleffi from "@/components/caleffi/caleffi.vue";
import caleffi_5334 from "@/components/caleffi/caleffi_5334.vue";
import caleffi_5020 from "@/components/caleffi/caleffi_5020.vue";
import suntec from "@/components/suntec/suntec.vue";
import suntec_an from "@/components/suntec/suntec_an.vue";
import suntec_as from "@/components/suntec/suntec_as.vue";
import suntec_aj from "@/components/suntec/suntec_aj.vue";
import suntec_j from "@/components/suntec/suntec_j.vue";
import suntec_e from "@/components/suntec/suntec_e.vue";
import suntec_ta from "@/components/suntec/suntec_ta.vue";
import suntec_tar from "@/components/suntec/suntec_tar.vue";
import suntec_t from "@/components/suntec/suntec_t.vue";
import suntec_tv from "@/components/suntec/suntec_tv.vue";
import ceoMent from "@/components/ceoMent.vue";
import findLoad from "@/components/findLoad.vue";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",

  routes: [
    { path: "/", name: "home", component: () => import("@/views/home.vue") },
    {
      path: "/items",
      name: "items",
      component: itemsView,
      children: [
        {
          path: "danfoss",
          name: "danfoss",
          component: danfoss,
        },
        {
          path: "danfoss_nozzle",
          name: "danfoss_nozzle",
          component: danfoss_nozzle,
        },
        {
          path: "danfoss_dpf",
          name: "danfoss_dpf",
          component: danfoss_dpf,
        },
        {
          path: "danfoss_bfp",
          name: "danfoss_bfp",
          component: danfoss_bfp,
        },
        {
          path: "danfoss_ebi4",
          name: "danfoss_ebi4",
          component: danfoss_ebi4,
        },
        {
          path: "danfoss_mbs",
          name: "danfoss_mbs",
          component: danfoss_mbs,
        },
        {
          path: "hago",
          name: "hago",
          component: hago,
        },
        {
          path: "hago_nozzle",
          name: "hago_nozzle",
          component: hago_nozzle,
        },
        {
          path: "annaka",
          name: "annaka",
          component: annaka,
        },
        {
          path: "annaka_glass",
          name: "annaka_glass",
          component: annaka_glass,
        },
        {
          path: "hp_technik",
          name: "hp_technik",
          component: hp_technik,
        },
        {
          path: "hp_ponpump",
          name: "hp_ponpump",
          component: hp_ponpump,
        },
        {
          path: "hp_uhepump",
          name: "hp_uhepump",
          component: hp_uhepump,
        },
        {
          path: "hp_valve1",
          name: "hp_valve1",
          component: hp_valve1,
        },
        {
          path: "hp_valve2",
          name: "hp_valve2",
          component: hp_valve2,
        },
        {
          path: "hp_valve3",
          name: "hp_valve3",
          component: hp_valve3,
        },
        {
          path: "nippon",
          name: "nippon",
          component: nippon,
        },
        {
          path: "nippon_vsc",
          name: "nippon_vsc",
          component: nippon_vsc,
        },
        {
          path: "nippon_vskx",
          name: "nippon_vskx",
          component: nippon_vskx,
        },
        {
          path: "caleffi",
          name: "caleffi",
          component: caleffi,
        },
        {
          path: "caleffi_5334",
          name: "caleffi_5334",
          component: caleffi_5334,
        },
        {
          path: "caleffi_5020",
          name: "caleffi_5020",
          component: caleffi_5020,
        },
        {
          path: "suntec",
          name: "suntec",
          component: suntec,
        },
        {
          path: "suntec_an",
          name: "suntec_an",
          component: suntec_an,
        },
        {
          path: "suntec_as",
          name: "suntec_as",
          component: suntec_as,
        },
        {
          path: "suntec_aj",
          name: "suntec_aj",
          component: suntec_aj,
        },
        {
          path: "suntec_j",
          name: "suntec_j",
          component: suntec_j,
        },
        {
          path: "suntec_e",
          name: "suntec_e",
          component: suntec_e,
        },
        {
          path: "suntec_ta",
          name: "suntec_ta",
          component: suntec_ta,
        },
        {
          path: "suntec_tar",
          name: "suntec_tar",
          component: suntec_tar,
        },
        {
          path: "suntec_t",
          name: "suntec_t",
          component: suntec_t,
        },
        {
          path: "suntec_tv",
          name: "suntec_tv",
          component: suntec_tv,
        },
      ],
    },
    {
      path: "/info",
      name: "info",
      component: infoView,
      children: [
        {
          path: "ceoment",
          name: "ceoment",
          component: ceoMent,
        },
        {
          path: "findload",
          name: "findload",
          component: findLoad,
        },
      ],
    },
  ],
});
