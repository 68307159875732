<template>
  <div class="main">
    <div class="white_bg">
      <h2>Document</h2>
      <div class="btns">
        <div>
          <a href="/file/GEARPUMP-BFP_10_11__3_5_6.pdf" target="_blank"
            >BFP 10/11 (3,5,6)</a
          >
        </div>
        <div>
          <a href="/file/GEARPUMP-BFP_10_11__8_11_13.pdf" target="_blank"
            >BFP 10/11 (8,11,13)</a
          >
        </div>
        <div>
          <a href="/file/GEARPUMP-BFP_20_21__3_5.pdf" target="_blank"
            >BFP 20/21 (3,5)</a
          >
        </div>
        <div>
          <a href="/file/GEARPUMP-BFP_41__3_5.pdf" target="_blank"
            >BFP 41 (3,5)</a
          >
        </div>
        <div>
          <a href="/file/GEARPUMP-BFP_52__3_5.pdf" target="_blank"
            >BFP 52 (3,5)</a
          >
        </div>
        <div>
          <a href="/file/GEARPUMP-BFP_52__6_8_11_13.pdf" target="_blank"
            >BFP 52 (6,8,11,13)</a
          >
        </div>
        <div>
          <a href="/file/DANFOSS_RSA.pdf" target="_blank">RSA</a>
        </div>
        <div style="margin-bottom: 0">
          <a href="/file/DANFOSS_RSH.pdf" target="_blank">RSH</a>
        </div>
      </div>
      <div class="closeBtn" @click="closeBtn">닫기</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },
  methods: {
    closeBtn() {
      document.getElementsByTagName("body")[0].style.overflow = "scroll";

      this.$store.commit("danfoss_bfp_popup");
    },
  },
};
</script>

<style scoped>
a {
  color: #686868;
  text-decoration: none;
  outline: none;
}
.main {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
}
.white_bg {
  width: 400px;
  height: 600px;
  background: #fff;
  margin: 80px auto;
  border-radius: 8px;
}
.white_bg h2 {
  font-size: 30px;
  text-align: center;
  padding-top: 30px;
}
.btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.btns div {
  width: 280px;
  height: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.closeBtn {
  width: 200px;
  height: 40px;
  color: #fff;
  background: red;
  font-size: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  cursor: pointer;
}
</style>
