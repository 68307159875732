<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>SUNTEC</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          PUMP AS
        </div>
      </div>
      <a href="/file/suntec/PUMP_AS.pdf" target="_blank">
        <span style="font-size: 15px">PUMP_AS.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <div class="box1">
        <img
          src="@/img/상세페이지/suntec/목록/suntec_as.png"
          alt=""
          style="width: 350px"
        />
        <div>
          <p>
            SUNTEC AS 오일 펌프에는 조절기 차단 밸브를 제어하는 ​​솔레노이드
            밸브가 내장되어 회전 속도와 관계없이 빠른 차단 및 차단 기능을
            제공합니다.
          </p>
          <img
            src="@/img/상세페이지/suntec/suntec_an2.png"
            alt=""
            style="width: 310px; margin-top: 15px"
          />
        </div>
      </div>
      <div class="box2">
        <div>
          <p>
            기어 세트는 내장된 필터를 통해 탱크에서 오일을 끌어와 노즐 라인의
            오일 압력을 조절하는 밸브로 전달합니다. 노즐 라인을 통과하지 못한
            모든 오일은 밸브를 통해 2파이프 설치의 리턴 라인으로 다시
            배출되거나, 1파이프 설치의 경우 기어 세트의 흡입 포트로 다시
            배출됩니다. 이 경우 바이패스 플러그를 리턴 포트에서 제거하고 리턴
            포트를 강철 플러그와 와셔로 밀봉해야 합니다.<br /><br />
            AS 펌프의 솔레노이드 밸브는 "상시 열림" 유형입니다.<br />
            솔레노이드 밸브가 비활성화되면 밸브의 압력 측과 복귀 측 사이의
            바이패스 채널이 열립니다. 그러면 밸브를 열기 위한 압력이 형성되지
            않습니다. 기어 세트의 속도는 중요하지 않습니다.<br /><br />
            솔레노이드가 활성화되면 이 바이패스 채널이 닫히고 기어 세트의 최대
            속도로 인해 밸브를 여는 데 필요한 압력이 매우 빠르게 형성되어 매우
            날카로운 컷온 기능을 제공합니다. <br /><br /><strong
              style="font-weight: 600; color: black"
              >차단 버너가 정지</strong
            ><br />
            하면 솔레노이드가 동시에 바이패스를 열어 모든 오일을 리턴으로
            배출하고 노즐 밸브가 즉시 닫힙니다. 이는 매우 날카로운 차단 기능을
            제공합니다.<br />
            컷온 및 컷오프는 모터 속도에 관계없이 작동할 수 있으며 매우 빠른
            응답을 제공합니다. <br />솔레노이드가 활성화되지 않으면 최대 모터
            속도까지 토크 요구 사항이 낮습니다.<br /><br />
            <strong style="font-weight: 600; color: black">블리드</strong><br />
            두 파이프 작업의 블리딩은 자동으로 이루어지지만 압력 게이지 포트의
            플러그를 느슨하게 하면 속도가 빨라질 수 있습니다.<br />
            한 번의 파이프 작업에서는 시스템에서 공기를 빼내기 위해 압력 포트를
            열어야 합니다.
          </p>
        </div>
        <div>
          <img
            src="@/img/상세페이지/suntec/suntec_as1.png"
            style="width: 250px; margin-left: 15px"
          />
        </div>
      </div>
      <img
        src="@/img/상세페이지/suntec/suntec_as2.png"
        style="width: 840px; margin-top: 60px"
      />
    </div>
    <div class="data_sheet">
      <a href="/file/suntec/PUMP_AS.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 {
  display: flex;
  align-items: center;
}
.box1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box1 > div > p,
.box2 > div > p {
  color: #686868;
  line-height: 1.7;
}
.box2 {
  margin-top: 55px;
  display: flex;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
