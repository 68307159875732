<template>
  <div class="danfoss">
    <div class="title">
      <div>
        <h2>ANNAKA</h2>
        <div style="font-size: 24px; margin-top: 6px; color: #686868">
          GAUGE GLASS
        </div>
      </div>
      <a href="/file/ANNAKA_GLASS.pdf" target="_blank">
        <span style="font-size: 15px">ANNAKA_GLASS.pdf</span>
        <img
          src="@/img/download.png"
          alt=""
          style="width: 20px; margin-left: 6px"
        />
      </a>
    </div>
    <div class="contents">
      <p>
        수면계용 유리란, 확인이 필요한 탱크내나 용기내 등의 액체잔을,<br />외부로부터
        육안으로 볼 수 있도록 외부에 설치하는 유리의 총칭입니다. <br />주로
        육용·선박용 보일러에 폭넓게 사용되며,<br />
        고온·고압, 급격한 열충격에 견딜 수 있는 특성이 있습니다.
      </p>
      <div class="wrap">
        <div class="box">
          <h2>특징</h2>
          <p>
            반사식의 경우, 액체부가 흑색, 기체부가 은백색으로 보이기 때문에,
            무색 투명한 액체에서도 액위를 명료하게 식별할 수 있습니다. 투시식은
            압력의 더 강한 부분에도 사용할 수 있습니다.
            투시식・반사식・2색식・자외선 컷의 착색 타입 등 다종의 라인업과,
            다수의 사이즈를 갖추고 있으므로, 지정 질재나 치수의 요망에 대응할 수
            있습니다.
          </p>
        </div>
        <img
          src="@/img/상세페이지/아나카/수면계유리1.png"
          alt=""
          style="margin-left: 30px"
        />
      </div>
      <div class="wrap">
        <div class="box">
          <h2>용도</h2>
          <p>
            보일러 엿보기 창에 사용됩니다.<br />
            &#60; 제품 예 &#62; <br />선박용 보일러<br />육용 보일러
          </p>
        </div>
        <img
          src="@/img/상세페이지/아나카/수면계유리2.png"
          alt=""
          style="margin-left: 30px"
        />
      </div>
      <div class="size">
        <h2>치수</h2>
      </div>
      <div class="wrap">
        <img
          src="@/img/상세페이지/아나카/치수1.png"
          alt=""
          style="margin-right: 30px"
        />

        <div class="box">
          <h2>평형 반사식 수면계 유리의 형상 및 치수</h2>
          <p>
            최고사용 2.5Mpa<br />{25kgf/㎠}이하<br />
            ※수면계를 장착하는 보일러의 최고사용압력을 가지고 나타낸다.
          </p>
        </div>
      </div>
      <div class="wrap">
        <img
          src="@/img/상세페이지/아나카/치수2.png"
          alt=""
          style="margin-right: 30px"
        />

        <div class="box">
          <h2>평형 투시식 수면계 유리의 형상 및 치수</h2>
          <p>
            최고사용 12.0Mpa<br />{120kgf/㎠}이하<br />
            ※수면계를 장착하는 보일러의 최고사용압력을 가지고 나타낸다.
          </p>
        </div>
      </div>
      <div class="wrap">
        <img
          src="@/img/상세페이지/아나카/치수표.png"
          alt=""
          style="margin-right: 30px"
        />

        <div class="box">
          <h2>치수표</h2>
          <p>
            평형 외에, 원형·원형 투시식도 준비하고 있습니다. 제품에 대한 자세한
            내용은 문의하십시오.
          </p>
        </div>
      </div>
    </div>
    <div class="data_sheet">
      <a href="/file/ANNAKA_GLASS.pdf" target="_blank">
        <span style="font-size: 20px">제품 카탈로그 다운로드</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.danfoss {
  width: 840px;
}
.title {
  font-size: 30px;
  border-bottom: 1px solid #191919;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #191919;
  text-decoration: none;
}
.contents {
  width: 840px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contents > p {
  text-align: center;
  margin: 40px 0;
  font-size: 20px;
  line-height: 2.2;
  font-weight: 600;
}
.wrap {
  width: 840px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  border-bottom: 1px solid #dbdbdbdb;
  padding-bottom: 60px;
}
.wrap img {
  width: 380px;
}
.box h2 {
  line-height: 1.8;
  font-weight: 600;
  font-size: 22px;
  color: #1e73be;
  margin-bottom: 20px;
}
.box p {
  line-height: 2.2;
  font-size: 16px;
}
.size > h2 {
  line-height: 1.8;
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  margin: 30px;
  border-top: 2px solid #1e73be;
}
.data_sheet {
  width: 300px;
  height: 60px;
  background-color: #1e73be;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 50px auto;
  cursor: pointer;
}
.data_sheet a {
  text-decoration: none;
  color: #fff;
}
</style>
